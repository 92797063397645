<script lang="ts" setup>
import type { PlayersActivityGame } from "~/types";

import type { KeenSliderOptions } from "keen-slider";

const { open } = useAppModals();
const { handleOpenGame } = useOpenGame(open);
const loginGuard = useLoginGuard();

const changeSlideDisabled = ref(false);
const sliderRef = ref();
const sliderActiveSlide = ref(0);
const isLast = ref(false);

const { playersActivity, fetchedPlayersActivity } = useRecentGamesWinners();
const documentVisibleState = useDocumentVisibility();

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "snap",
	slides: { perView: "auto" },
	created(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details?.abs || 0;
	},
	detailsChanged(slider) {
		isLast.value = slider.track.details?.slides?.[fetchedPlayersActivity.value.length - 1]?.portion >= 0.98;
	}
};

const updateAfterAnimation = debounce(() => {
	sliderRef.value?.slider?.update();
	changeSlideDisabled.value = false;
}, 900);

const updateSlider = () => {
	changeSlideDisabled.value = true;
	updateAfterAnimation();
};

const handleClickCard = (item: PlayersActivityGame) => {
	loginGuard({
		success: () => {
			if (!item?.slug) {
				return;
			}
			handleOpenGame(item?.slug);
			dispatchGAEvent({
				event: "click_button",
				location: item?.slug ?? "",
				button_name: "gameplay",
				form_name: "LATEST WINNERS"
			});
		},
		fail: () => open("LazyOModalSignup")
	});
};

watch(
	() => playersActivity,
	() => updateSlider(),
	{ deep: true }
);
</script>
<template>
	<div v-if="fetchedPlayersActivity?.length" class="activity-feed" :class="{ 'disable-drag': changeSlideDisabled }">
		<ClientOnly>
			<ASlider v-if="documentVisibleState === 'hidden'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
					<div v-for="item in fetchedPlayersActivity" :key="`card-${item.type}-${item.id}`" class="keen-slider__slide">
						<MHomeActivityFeedCard :winner="item" @openGame="handleClickCard" />
					</div>
				</TransitionGroup>
			</ASlider>
			<ASlider v-if="documentVisibleState === 'visible'" ref="sliderRef" :options="sliderOptions">
				<TransitionGroup :name="sliderActiveSlide > 0 ? 'list-width' : 'list-transform'">
					<div v-for="item in playersActivity" :key="`card-${item.type}-${item.id}`" class="keen-slider__slide">
						<MHomeActivityFeedCard :winner="item" @openGame="handleClickCard" />
					</div>
				</TransitionGroup>
			</ASlider>
		</ClientOnly>
	</div>
</template>
<style lang="scss" scoped>
.disable-drag {
	pointer-events: none;
}

.list-move {
	transition: transform 0.5s ease;
}

.activity-feed {
	padding: gutter(3) 0;
	position: relative;

	&::before {
		content: "";
		display: block;
		position: absolute;
		right: 0;
		top: 16px;
		bottom: 16px;
		width: 113px;
		background: var(--godhra);
		z-index: 1;

		@include media-breakpoint-down(md) {
			content: none;
		}
	}

	@include media-breakpoint-down(md) {
		padding: gutter(2.5) 0;
		padding-left: gutter(2);
	}

	&:deep(.keen-slider) {
		display: flex;
		position: relative;
	}

	.keen-slider__slide {
		flex-shrink: 0;
		width: calc(229px + 16px);
		padding-right: gutter(2);

		@include media-breakpoint-down(md) {
			width: calc(168px + 8px);
			padding-right: gutter(1);
		}
	}
}
.flip-list-move {
	transition: transform 0.8s ease;
}

.list-transform-move {
	transition: transform 0.8s;
}

.list-width-enter-active,
.list-width-leave-active {
	transition:
		min-width 0.8s,
		width 0.8s,
		opacity 0.8s;
}
.list-width-enter-from,
.list-width-leave-to {
	opacity: 0;
	width: 0 !important;
	min-width: 0 !important;
	max-width: 0 !important;
}
</style>
